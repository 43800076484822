import React, { useContext } from "react";
import Typography from "components/Typography";
import AppButton from "components/AppButton";
import { BusinessContext } from "contexts/BusinessProvider";

function SectionBusinessEssentials(props) {
  const { setOpen: setBusinessOpen } = useContext(BusinessContext);
  return (
    <div className="px-4 py-10 sm:px-0">
      <div className="max-w-4xl mx-auto text-left">
        <Typography variant="h3" className="mb-6 text-4xl sm:text-5xl ">
        Tailor-made solution
        </Typography>
        <Typography variant="caption2" className="mb-6">
        Every company has specific needs. We invite you to get in touch with our sales team to discuss a tailor-made solution and kick-off collaboration.
        </Typography>
        <AppButton
          title="Request a demo"
          as="a"
          background="black"
          size="base"
          to="https://calendly.com/ooofer/ooofer-demo"
        />
      </div>
    </div>
  );
}

export default SectionBusinessEssentials;
