import * as React from "react";
import Typography from "components/Typography";
import Steps from "components/steps/Steps";
import FadeInEffect from "../components/FadeInEffect";
import step1 from '../images/steps/step1.jpg'
import step2 from '../images/steps/step2.jpg'
import step3 from '../images/steps/step3.jpg'

const steps = [
  { title: 'Book', description: 'Look for the perfect hotel hub and book a workspace' },
  { title: 'Check-in', description: 'Check-in at reception and get welcomed by the staff' },
  { title: 'Work & Enjoy', description: 'Get to work or take a break to enjoy gyms, spas, food and more' },
]

export const Section1 = () => {
  return (
    <div className="py-10" id="overview">
      <div className="max-w-5xl mx-auto text-center">
        <div className="px-4 pb-5 sm:px-0">

          <Typography id="overview" variant="h2" className="mb-8">
          Hotel workspaces...
          <br />
          and so much more.
          </Typography>
          <Typography variant="caption2" className="m-auto mb-6">
            <p className="mb-5">
              <strong>A booking App</strong> for curated <strong>hotel coworking spaces.</strong>
              <br />
              But that’s the tip of the iceberg.
            </p>
            <p className="mb-5">
              Member rates on <strong>meeting rooms</strong> and <strong>bedrooms.</strong>
              <br />
              Privileges at hotel <strong>gyms, spas,  restaurants & bars.</strong>
            </p>
          </Typography>
        </div>
        <FadeInEffect>
          <div className="hidden gap-5 lg:grid lg:grid-cols-3">
            <div className="relative rounded-xl">
              <img src={step1} alt={step1.title} />
              <div className="absolute top-5 left-10">
                <div className="flex items-start gap-5 text-left">
                  <div className="flex items-center justify-center w-10 h-10 mt-0 text-2xl text-white bg-black rounded-full">1</div>
                  <div>
                      <div className="py-0 text-2xl">
                        <strong>{steps[0].title}</strong>
                      </div>
                      <div className="w-52">{steps[0].description}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative rounded-xl">
              <img src={step2} alt={step1.title} />
              <div className="absolute bottom-5 left-10">
                <div className="flex items-start gap-5 text-left">
                  <div className="flex items-center justify-center w-10 h-10 mt-0 text-2xl text-white bg-black rounded-full">2</div>
                  <div>
                      <div className="py-0 text-2xl">
                        <strong>{steps[1].title}</strong>
                      </div>
                      <div className="w-52">{steps[1].description}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative rounded-xl">
              <img src={step3} alt={step1.title} />
              <div className="absolute top-5 left-10">
                <div className="flex items-start gap-5 text-left">
                  <div className="flex items-center justify-center w-10 h-10 mt-0 text-2xl text-white bg-black rounded-full">3</div>
                  <div>
                      <div className="py-0 text-2xl">
                        <strong>{steps[2].title}</strong>
                      </div>
                      <div className="w-52">{steps[2].description}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block lg:hidden">
            <Steps steps={steps}></Steps>
          </div>
        </FadeInEffect>
      </div>
    </div>
  );
};
