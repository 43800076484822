import React, { useContext } from "react";
import Typography from "components/Typography";
import { BusinessContext } from "contexts/BusinessProvider";
import splitbee from '@splitbee/web';

function SubscriptionPrice({ type, price, ...other }) {
  const { setOpen: setBusinessOpen } = useContext(BusinessContext);

  const handleClick = () => {
    splitbee.track('Subscription click', {
      type,
    });
    setBusinessOpen(true);
  };
  
  return (
    <div {...other}>
      <Typography variant="h4">{type}</Typography>
      <Typography variant="h4" className="mb-2">
        {price}
      </Typography>
    </div>
  );
}

export default SubscriptionPrice;
