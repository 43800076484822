import * as React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import Typography from "components/Typography";

const faqs = [
  {
    question: "How do I implement ooofer in my company ?",
    answer:
      "There is no technical set-up required as ooofer is a downloadable app. It works as a separate environment from your internal systems. All your employees can access it directly. We also take care of information sessions and product documentation for your employees.",
  },
  {
    question: "How do I know about the frequency of usage from the employees within my company ?",
    answer:
      "We send a detailed monthly report to the person in charge within your company. You will get clear metrics about the level of usage and the main trends. Tailor-made reports are also possible upon request.",
  },
  {
    question: "Are the internet connexions secured ?",
    answer:
      "We only select premium and high-end hotels with private Wi-Fi connections using WPA2 security protocol.",
  },
  {
    question: "How do transactions for additional services take place in the app ?",
    answer:
      "All the additional services can be booked directly in the app. Nevertheless, all payments take place at the hotel directly.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const FaqCorporate = () => {
  const h2 = "Questions? Answered.";
  return (
    <div className="px-4 py-10 sm:px-0">
      <div className="max-w-4xl mx-auto">
        <Typography variant="h2">{h2}</Typography>
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
                      <span className="font-medium text-gray-900">
                        {faq.question}
                      </span>
                      <span className="flex items-center ml-6 h-7">
                        <ChevronDownIcon
                          className={classNames(
                            open ? "-rotate-180" : "rotate-0",
                            "h-6 w-6 transform"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform opacity-100"
                    leaveTo="transform opacity-0"
                  >
                    <Disclosure.Panel as="dd" className="pr-12 mt-2" static>
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};
