import React from "react";
import { useEffect, useRef, useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function FadeInEffect({ className, children }) {
  const cardRef = useRef(null);
  const [show, setShow] = useState(false);

  const observeHandler = (entries, observer) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setShow(true);
      observer.unobserve(entry.target);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      once: true,
      rootMargin: "0% 50%",
      threshold: 0.5,
    };
    const refCard = cardRef.current;
    const observer = new IntersectionObserver(observeHandler, options);
    if (refCard) observer.observe(refCard);

    return () => {
      if (refCard) observer.unobserve(refCard);
    };
  }, [cardRef]);

  return (
    <div
      ref={cardRef}
      className={classNames(
        "transition-all duration-500",
        show ? "opacity-100 translate-y-0" : "opacity-0 translate-y-6",
        "shrink-0",
        className
      )}
    >
      {children}
    </div>
  );
}

export default FadeInEffect;
