import * as React from "react";
import Typography from "components/Typography";
import Steps from "components/steps/Steps";
import FadeInEffect from "../components/FadeInEffect";

export const SectionDownsize = () => {
  return (
    <div className="py-10 ">
      <div className="max-w-4xl mx-auto text-center">
        <div className="px-4 sm:px-0">
          <Typography id="what" variant="h2" className="mb-6">
            Cut costs on offices ...
          </Typography>
          <Typography variant="caption2" className="mb-6">
          We help your company make savings on office space. 
          </Typography>
          <Typography variant="caption2" className="mb-6">
          Give your employees access to our hotel work hubs and reduce office size, save on rent, energy, maintenance, hardware and consumables. 
          </Typography>
          <Typography variant="caption2" className="mb-6">
          With ooofer your team can access coworking spaces and business lounges at an affordable fixed cost.  And book ad-hoc meeting rooms at negotiated prices when they need to gather. 
           </Typography>
        </div>
        <div className="px-4 pt-10 md:pb-16 sm:px-0">
          <Typography id="what" variant="h2" className="mb-6">
            ... & so much more.
          </Typography>
          <Typography variant="caption2" className="mb-6">
          Working in our curated spaces encourages collaboration and innovation. 
          </Typography>
          <Typography variant="caption2" className="mb-6">
          Your team is exposed to new ideas and perspectives that they don’t encounter in a traditional office setting, nor at home. 
          </Typography>
          <Typography variant="caption2" className="mb-6">
          Furthermore they can access on-site wellness & sport infrastructures to keep a good work-life balance at exclusive rates.
           </Typography>
        </div>

        <FadeInEffect>
          <Steps steps={
            [
              { title: 'Contact', description: 'Our sales team will build a tailor-made solution with you' },
              { title: 'Implementation', description: 'Employees download the App and all accounts are activated' },
              { title: 'Booking', description: 'Your team is now ready to book in all our hotel hubs !' },
            ]
          }></Steps>
        </FadeInEffect>
      </div>
    </div>
  );
};
