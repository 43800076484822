import React, { useContext } from "react";
import heroImg from "images/corporate/hero.jpg";
import AppButton from "../components/AppButton";
import Typography from "components/Typography";
import { BusinessContext } from "contexts/BusinessProvider";

export const HeroCorporate = () => {
  const { setOpen: setBusinessOpen } = useContext(BusinessContext);
  return (
    <div className="relative mx-auto">
      <div className="absolute inset-0 w-ful">
        <img
          className="w-full h-[600px] sm:h-[694px] object-cover mx-auto"
          src={heroImg}
          alt="hero section"
        />
      </div>
      <div className="relative h-[600px] sm:h-[694px] flex justify-center items-center text-center">
        <div>
          <Typography variant="title" className="px-4">
          We make your <br className="hidden sm:block" />
          workplace more <br className="hidden sm:block" />
          flexible & cost effective.
          </Typography>
          <div className="flex flex-row items-center justify-center gap-4 mt-16">
            <AppButton
              as="a"
              to="https://calendly.com/ooofer/ooofer-demo"
              background="white"
              size="base"
              
              title="Request a demo"
            />
            <AppButton
              as="a"
              to="/corporate/#overview"
              size="base"
              stripHash
              background="outline"
              title="Learn more"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
