import * as React from "react";
import Typography from "components/Typography";

import img1 from "images/talent/talent1.jpg";
import img2 from "images/talent/talent2.png";
import img3 from "images/talent/talent3.png";
import FadeInEffect from "../components/FadeInEffect";

export const SectionRetainTalent = () => {
  return (
    <div className="py-10 " id="overview">
      <div className="max-w-4xl mx-auto text-center">
        <div className="px-4 sm:px-0">
          <Typography id="what" variant="h2" className="mb-6">
            Agile workspaces.
          </Typography>
          <Typography variant="caption2" className="mb-6">
          The corporate world is changing at a fast pace. <br />
          Hybrid work is soaring and traditional central office spaces are becoming 
          less effective.
          </Typography>
          <Typography variant="caption2" className="mb-6">
          We offer a simple and creative solution to reduce and optimise cumbersome and expensive office space rentals.
          </Typography>
          <Typography variant="caption2" className="mb-6">
            An all-in one app that gives your team access to work hubs wherever they go.
           </Typography>
        </div>

        <FadeInEffect>
          <img src={img1} alt="Img3" className="block w-full sm:hidden" />
        </FadeInEffect>

        <div className="hidden max-w-4xl grid-cols-3 gap-3 mx-auto mt-8 md:grid">
          <FadeInEffect className="">
            <img src={img1} alt="Img3" className=" h-80 w-80" />
          </FadeInEffect>

          <FadeInEffect className="">
            <img src={img2} alt="Img2" className="h-80 w-80" />
          </FadeInEffect>
          <FadeInEffect className="">
            <img src={img3} alt="Img1" className="h-80 w-80" />
          </FadeInEffect>
        </div>
      </div>
    </div>
  );
 };
