import * as React from "react";
import { HeroCorporate } from "../sections/HeroCorporate";
import { Section1 } from "../sections/Section1";
import { SectionDownsize } from "../sections/SectionDownsize";
import { SectionRetainTalent } from "../sections/SectionRetainTalent";
import Navbar from "../sections/Navbar";
import Seo from "../sections/seo";
import { SectionPlansComparison } from "../sections/SectionPlansComparison";
import { FaqCorporate } from "../sections/FaqCorporate";
import SocialMedia from "../sections/SocialMedia";
import WaitingListModal from "components/modal/WaitingListModal";
import WaitingListProvider from "contexts/WaitingListProvider";
import BusinessProvider from "../contexts/BusinessProvider";
import BusinessModal from "components/modal/BusinessModal";
import HotelProvider from "../contexts/HotelProvider";
import HotelModal from "../components/modal/HotelModal";
import SectionTailorMade from "../sections/SectionTailorMade";
import SectionWorkspacesCorporate from "../sections/SectionWorkspacesCorporate";
import SectionMemberBenefits from "../sections/SectionMemberBenefits";
import Footer from "../sections/Footer";
import CookieBanner from "components/CookieBanner";
export default function Index() {
  return (
    <div>
      <Seo />
      <SocialMedia />
      <HotelProvider>
        <BusinessProvider>
          <WaitingListProvider>
            <WaitingListModal />
            <BusinessModal />
            <HotelModal />
            <Navbar />
            <HeroCorporate />
            <SectionRetainTalent />
            <SectionDownsize />
            <SectionWorkspacesCorporate/>
            <SectionTailorMade />
          </WaitingListProvider>
        </BusinessProvider>
      </HotelProvider>
      <FaqCorporate />
      <Footer />
      <CookieBanner />
    </div>
  );
}
