import React from "react";
import Typography from "components/Typography";
import { CheckIcon } from "@heroicons/react/outline";

function SubscriptionCheck({ included }) {
  return (
    <div className="self-center mx-auto">
      {(typeof included === 'string' && included) || (included && <CheckIcon className="w-5 h-5" />)}
    </div>
  );
}

function SubscriptionDetail({ title, includes }) {
  return (
    <div className="grid grid-cols-3 text-gray-400 sm:grid-cols-6 sm:border-b sm:border-gray-200">
      <Typography
        variant="subtitle1"
        className="col-span-3 py-2 text-left border-b border-gray-200 sm:py-4 sm:border-0"
      >
        {title}
      </Typography>
      <div className="grid grid-cols-3 col-span-3 py-2 sm:py-4">
        <SubscriptionCheck included={includes[0]} />
        <SubscriptionCheck included={includes[1]} />
        <SubscriptionCheck included={includes[2]} />
      </div>
    </div>
  );
}

export default SubscriptionDetail;
