import * as React from "react";
import Typography from "components/Typography";
import SubscriptionDetail from "components/SubscriptionDetail";
import SubscriptionPrice from "../components/SubscriptionPrice";
import AppButton from "../components/AppButton";

export const SectionPlansComparison = () => {
  return (
    <div className="px-4 py-10 sm:px-0">
      <div className="max-w-4xl mx-auto text-center">
        <Typography id="subscriptions" variant="h2" className="mb-6 sm:mb-10">
        Monthly subscriptions.
        <br />
        Unlimited coworking & perks.
        </Typography>

        <div className="grid pb-6 sm:grid-cols-6 sm:pb-3 sm:border-b sm:border-gray-200">
          <div className="hidden col-span-3 sm:block">
            {/* placeholder for columns */}
          </div>
          <div className="grid grid-cols-3 col-span-3 gap-6">
            {subscriptionPrices.map((price, index) => (
              <SubscriptionPrice
                key={index}
                type={price.type}
                price={price.price}
              />
            ))}
          </div>
        </div>

        {/* For mobile only sticky headers */}
        <div className="sticky top-0 grid grid-cols-3 gap-3 my-3 bg-white sm:hidden ">
          {subscriptionPrices.map((price, index) => (
            <Typography
              variant="body1"
              className="font-semibold text-left border-b-2 border-black"
            >
              {price.type}
            </Typography>
          ))}
        </div>

        {/* grid-cols-6 */}
        {subscriptionDetails.map((sub, index) => (
          <SubscriptionDetail
            key={index}
            title={sub.title}
            includes={sub.includes}
          />
          ))}
        <div className="grid gap-5 mt-5 mb-6 sm:grid-cols-6 sm:mb-3">
          <div className="hidden col-span-3 sm:block">
            {/* placeholder for columns */}
          </div>
          <div className="grid grid-cols-3 col-span-3 gap-6 sm:gap-5">
              <div>
                  <AppButton
                    title="Go Free"
                    size="sm"
                    as="a"
                    background="black"
                    to="/redirect-to-store"
                    />
                </div>
                <div>
                  <AppButton
                    title="Go Local"
                    size="sm"
                    as="a"
                    background="black"
                    to="/redirect-to-store"
                    />
                </div>
                <div>
                  <AppButton
                    title="Go Global"
                    size="sm"
                    as="a"
                    background="black"
                    to="/redirect-to-store"
                    />
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const subscriptionPrices = [
  {
    type: "Free",
    price: "€0/mo.",
  },
  {
    type: "Local",
    price: "€49/mo.",
  },
  {
    type: "Global",
    price: "€59/mo.",
  },
];

const subscriptionDetails = [
  {
    title: "Two monthly coworking sessions",
    includes: ["All countries", false, false],
  },
  {
    title: "Unlimited coworking sessions",
    includes: [false, "One country", "All countries"],
  },
  {
    title: "Coworking guest invitations",
    includes: [true, true, true],
  },
  {
    title: "Member rates on gyms, spas & pools",
    includes: [true, true, true],
  },
  {
    title: "Discounts on food & beverages",
    includes: [true, true, true],
  },
  {
    title: "Member rates on meeting rooms",
    includes: [false, true, true],
  },
  {
    title: "Business rates on hotel bedrooms",
    includes: [false, true, true],
  },
];
