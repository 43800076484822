import React from "react";
import Typography from "components/Typography";

import img1 from "../images/workspaces/s2-p3.png";
import img2 from "../images/workspaces/s6-p2.png";
import img3 from "../images/workspaces/s6-p3.png";
import img4 from "../images/workspaces/s6-p4.png";

const relaxImages = [
  {
    src: img1,
    name: "Food & beverages",
  },
  {
    src: img2,
    name: "Hotel bedrooms & stays",
  },
  {
    src: img3,
    name: "Meeting rooms",
  },
  {
    src: img4,
    name: "Gyms, Pool and Spas",
  },
  
];

function Section6(props) {
  return (
    <div className="py-8">
      <div className="mx-auto text-center">
        <div className="max-w-4xl px-4 mx-auto mb-6 sm:px-0">
          <Typography variant="h2" className="mb-6 ">
          Get access to member benefits.
          </Typography>
          <Typography variant="caption2">
          Enhance your experience with special member accesses, rates and discounts on all hotel facilities.
          </Typography>
        <div className="relative flex justify-center pt-5">
          <div className="relative grid grid-cols-2 gap-3 lg:grid-cols-4">
              {relaxImages.map((img, index) => (
              <div key={index} className="shrink-0">
                  <img src={img.src} alt={img.title} className="" />
                  <Typography variant="bodySmall" className="mt-2 text-left">
                  <strong>{img.name}</strong>
                  </Typography>
              </div>
              ))}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Section6;
