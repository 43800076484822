import React from 'react';

function Steps({
  steps,
}: {
  steps: {
    title: string;
    description: string;
  }[];
}) {
  return (
    <div className="flex flex-col w-full gap-5 md:flex-row md:gap-0">
      {steps.map((step, index) => (
        <div className="text-center grow">
          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center w-full gap-3">
              <div
                className={`hidden md:block ${
                  index === 0 ? 'border-0' : 'border'
                } h-0 border-black grow`}
              ></div>
              <div className="flex items-center justify-center w-16 h-16 border-4 border-black rounded-full">
                <strong className="text-3xl">{index + 1}</strong>
              </div>
              <div
                className={`hidden md:block ${
                  index === steps.length - 1 ? 'border-0' : 'border'
                } h-0 border-black grow`}
              ></div>
            </div>
            <div className="py-3 text-2xl">
              <strong>{step.title}</strong>
            </div>
            <div className="w-48">{step.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Steps;
